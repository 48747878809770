import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoArrowForward, IoSearch } from 'react-icons/io5';

import * as Hooks from 'modules/search/hooks';

import Spinner from "components/Spinner";

import classes from './Search.module.scss';

const types = {
  shrine: 'shrine',
  top: 'top',
  mosque: 'mosque',
  church: 'church',
  museum: 'museum',
  theater: 'theater'
};

const Search = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [isSingleOne, setSingleOne] = useState(true);
  const [selected, setSelected] = useState(types.shrine);

  const { items, isFetched } = Hooks.useList({
    params: {
      search,
      type: selected
    }
  });

  const changeRadioInput = (type) => {
    setSearch('');
    setSelected(type);

    if (type === types.shrine || type === types.top) {
      setSingleOne(true);
    } else {
      setSingleOne(false);
    }
  }

  return (
    <section className={classes.section}>
      <div className='container'>
        <div className={classes.search}>
          <input
            onChange={e => setSearch(e.target.value)}
            value={search}
            type="text"
            placeholder={`${t('search')}...`}
          />
          <IoSearch fontSize={28} />
        </div>

        <div className={classes.content}>
          <div className={classes.left}>
            <ul className={classes.parts}>
              <li className={classes.part}>
                <h3>Saralash</h3>

                <ul>
                  <li>
                    <label htmlFor="checkbox1">
                      <input
                        onChange={() => changeRadioInput(types.shrine)}
                        checked={selected === types.shrine}
                        type="radio"
                        id='checkbox1'
                        name='filter'
                      />
                      {t('shrines')}
                    </label>
                  </li>

                  <li>
                    <label htmlFor="checkbox2">
                      <input
                        onChange={() => changeRadioInput(types.top)}
                        checked={selected === types.top}
                        type="radio"
                        id='checkbox2'
                        name='filter'
                      />
                      {t('top_shrines')}
                    </label>
                  </li>

                  <li>
                    <label htmlFor="checkbox3">
                      <input
                        onChange={() => changeRadioInput(types.mosque)}
                        checked={selected === types.mosque}
                        type="radio"
                        id='checkbox3'
                        name='filter'
                      />
                      {t('mosques')}
                    </label>
                  </li>

                  <li>
                    <label htmlFor="checkbox4">
                      <input
                        onChange={() => changeRadioInput(types.church)}
                        checked={selected === types.church}
                        type="radio"
                        id='checkbox4'
                        name='filter'
                      />
                      {t('churches')}
                    </label>
                  </li>

                  <li>
                    <label htmlFor="checkbox5">
                      <input
                        onChange={() => changeRadioInput(types.museum)}
                        checked={selected === types.museum}
                        type="radio"
                        id='checkbox5'
                        name='filter'
                      />
                      {t('museums')}
                    </label>
                  </li>

                  <li>
                    <label htmlFor="checkbox6">
                      <input
                        onChange={() => changeRadioInput(types.theater)}
                        checked={selected === types.theater}
                        type="radio"
                        id='checkbox6'
                        name='filter'
                      />
                      {t('theaters')}
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className={classes.right}>
            {!isFetched ? (
              <div className={classes.spinner}>
                <Spinner />
              </div>
            ) : (
              <ul>
                {items.map(item => (
                  <li key={item.id}>
                    <img src={item.photo[0]} alt="Img" width={173} height={173} />

                    <div>
                      <h3>{item.name[i18n.language]}</h3>

                      <p>{item.title[i18n.language]}</p>

                      <Link to={isSingleOne ? `/shrines/${item.regionId}/${item.id}` : `/object/${selected}/${item.id}`}>
                        <span>{t('more')}</span> <IoArrowForward/>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className={classes.buttonBox}>
          <button>{t('find')}</button>
        </div>
      </div>
    </section>
  )
}

export default Search;