import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useSingle = ({ essentialId }) => {
  const initialData = { item: Mappers.SubEssential() };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'single', essentialId],
    async () => {
      const { data } = await Api.Single({ essentialId });

      return { item: Mappers.SubEssential(data) };
    },
    { initialData, keepPreviousData: true, enabled: !!essentialId }
  );

  return { ...data, ...args };
};

export default useSingle;
