import React from 'react';
import { useTranslation } from "react-i18next";

import Slider from "components/Slider";

import classes from './MediaContent.module.scss';

const MediaContent = ({ item }) => {
   const { t, i18n } = useTranslation();

   return (
      <section className={classes.section}>
         <div className="container">
            <h2 className={classes.title}>
               <div className={classes.divider} /> {t('media_content')}
            </h2>

           <Slider
             items={item.photo}
             slide={src => (
               <div className={classes.slide} key={src}>
                 <img src={src} alt="Img" width='100%' height={432} />
               </div>
             )}
           />

            {!!item.audio[i18n.language] && (
              <div className={classes.audioBox}>
                 <span>{item.name[i18n.language]}</span>

                 <audio src={item.audio[i18n.language]} controls />
              </div>
            )}

           {!!item.video && (
             <div className={classes.videoBox}>
               <iframe width="100%" height={600}
                       src={`https://youtube.com/embed/${item.video}`}
                       frameBorder="0"
                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                       allowFullScreen
               ></iframe>
             </div>
           )}
         </div>
      </section>
   )
}

export default MediaContent;
