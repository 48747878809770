import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";

import Logo from 'assets/images/logo.png'

import Search from './components/Search';
import Language from './components/Language';
import Navigation from './components/Navigation';

import classes from './Header.module.scss';

const Header = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false);

  return (
    <header className={classes.header}>
      <div className="container">
        <div className={classes.content}>
          <div className={classes.logo}>
            <Link to='/'>
              <img src={Logo} alt="Logo" width={50} height={50} />
            </Link>
            <span className={classes.logoTitle}>{t('logo_title')}</span>
          </div>

          <Navigation {...{ show, setShow }} />

          <div className={classes.settings}>
            <Language />

            <Search />

            <div
              onClick={() => setShow(prev => !prev)}
              className={classes.menu}
            >
              <AiOutlineMenu size={24} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;