import React from 'react';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import  * as Hooks from 'modules/object/hooks';

import Spinner from "components/Spinner";

import Main from "./components/Main";
import Addition from "./components/Addition";
import Pictures from "./components/Pictures";

import classes from './ObjectSingle.module.scss';

const ObjectSingle = () => {
  const { t, i18n } = useTranslation();
  const { id, type } = useParams();

  const { item, isFetched } = Hooks.useSingle({
    params: {
      id,
      type
    }
  });

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <div className={classes.breadcrumb}>
          <Link to='/'>{t('nav.home')}</Link> / {item.name[i18n.language]}
        </div>

        <div className={classes.content}>
          <Addition
            location={item.location}
            address={item.address[i18n.language]}
            phone={item.phone}
            workTime={item.workTime}
            link={item.link}
          />

          <Main
            src={item.photo[0]}
            name={item.name[i18n.language]}
            title={item.title[i18n.language]}
            info={item.info[i18n.language]}
          />
        </div>

        {item.photo.length > 2 && (
          <Pictures photos={item.photo} />
        )}
      </div>
    </section>
  )
}

export default ObjectSingle;