import React from 'react';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import  * as Hooks from 'modules/food/hooks';

import Spinner from "components/Spinner";

import Main from "./components/Main";
import Pictures from "./components/Pictures";

import classes from './FoodSingle.module.scss';

const FoodSingle = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const { item, isFetched } = Hooks.useSingle({ id });

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <div className={classes.breadcrumb}>
          <Link to='/'>{t('nav.home')}</Link> / {item.name[i18n.language]}
        </div>

        <Main
          src={item.photo[0]}
          name={item.name[i18n.language]}
          title={item.title[i18n.language]}
          info={item.info[i18n.language]}
        />

        {item.photo.length > 2 && (
          <Pictures photos={item.photo} />
        )}
      </div>
    </section>
  )
}

export default FoodSingle;