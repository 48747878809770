import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useListByShrine = ({ params }) => {
  const initialData = { item: Mappers.Object() };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY_BY_SHRINE, 'list', params],
    async () => {
      const { data } = await Api.ListByShrine({ params });

      return {
        item: Mappers.Object(data?.data)
      };
    },
    { initialData, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useListByShrine;
