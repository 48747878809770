import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { data } from './data';

import classes from '../Regions.module.scss';

const Map = ({ items }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');
  const [region, setRegion] = useState(null);

  useEffect(() => {
    setRegion(() => items.find(item => String(item.id) === selected));
  }, [selected]); // eslint-disable-line

  return (
    <div className={classes.map}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label={data.name}
        viewBox={data.viewBox}
      >
        { data.layers.map(layer => (
          <path
            key={layer.id}
            tabIndex="0"
            aria-label={layer.name}
            aria-checked="false"
            aria-current="false"
            name={layer.name}
            id={layer.id}
            d={layer.d}
            onClick={() => navigate(`/regions/${layer.id}`)}
            onMouseOver={() => setSelected(layer.id)}
          />
        ))}
      </svg>

      {region && (
        <div className={classes.selected}>
          <h3 className={classes.name}>{region?.name[i18n.language]}</h3>
          <img className={classes.image} src={region?.photo[0]} alt={region?.name[i18n.language]} width={500} height={250} />
        </div>
      )}
    </div>
  )
}

export default Map;