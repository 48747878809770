import get from 'lodash/get';
import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useList = () => {
  const initialData = { items: [] };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'list'],
    async () => {
      const { data } = await Api.List();

      const items = (get(data, 'data') || []).map(item => Mappers.Essential(item));

      return { items };
    },
    { initialData, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useList;
