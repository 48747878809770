import './bootstrap';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import { YMaps } from "react-yandex-maps";

import ScrollToTop from 'wrappers/ScrollToTop';
import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
          <YMaps>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </YMaps>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);