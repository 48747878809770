import React, { useState } from 'react';
import { CgWebsite } from "react-icons/cg";
import { BsFacebook } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { TbBrandTelegram } from "react-icons/tb";
import { IoHourglassOutline } from "react-icons/io5";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";

import Modal from "components/Modal";

import classes from "../ObjectSingle.module.scss";

const Addition = ({ location, address, phone, workTime, link }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return  (
    <div className={classes.addition}>
      <h3>{t('additional_infos')}</h3>

      <ul className={classes.info}>
        {!!address && (
          <li>
            <div className={classes.icon}>
              <HiOutlineLocationMarker fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('address')}: </strong>
              <span onClick={() => setShow(true)} className={classes.link}>{address}</span>
            </div>
          </li>
        )}

        {!!phone && (
          <li>
            <div className={classes.icon}>
              <FiPhoneCall fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('phone')}: </strong>
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
          </li>
        )}

        {!!workTime && (
          <li>
            <div className={classes.icon}>
              <IoHourglassOutline fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('work_times')}: </strong>
              <span>{workTime}</span>
            </div>
          </li>
        )}
      </ul>
      
      <ul className={classes.networks}>
        {!!link.facebook && (
          <li>
            <a href={link.facebook} target='_blank' rel="noreferrer">
              <BsFacebook size={32} />
            </a>
          </li>
        )}
        {!!link.instagram && (
          <li>
            <a href={link.instagram} target='_blank' rel="noreferrer">
              <AiOutlineInstagram size={32} />
            </a>
          </li>
        )}
        {!!link.site && (
          <li>
            <a href={link.site} target='_blank' rel="noreferrer">
              <CgWebsite size={32} />
            </a>
          </li>
        )}
        {!!link.telegram && (
          <li>
            <a href={link.telegram} target='_blank' rel="noreferrer">
              <TbBrandTelegram size={32} />
            </a>
          </li>
        )}
        {!!link.youtube && (
          <li>
            <a href={link.youtube} target='_blank' rel="noreferrer">
              <AiFillYoutube size={32} />
            </a>
          </li>
        )}
      </ul>

      <Modal visible={show} onCancel={() => setShow(false)}>
        <div className={classes.maps}>
          <a className={classes.link} href={`https://www.google.com/maps/place/${location.x},${location.y}`} target='_blank' rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.3 132.3">
              <path fill="#1a73e8" d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"/>
              <path fill="#ea4335" d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"/>
              <path fill="#4285f4" d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"/>
              <path fill="#fbbc04" d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"/>
              <path fill="#34a853" d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"/>
            </svg>
            {t('open_in_google_map')}
          </a> <br/>
          <a className={classes.link} href={`https://yandex.ru/maps/?ll=${location.x},${location.y}&z=12`} target='_blank' rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 1C4.6862 1 2 3.6862 2 7C2 8.6563 2.6711 10.156 3.7565 11.2417C4.8422 12.328 7.4 13.9 7.55 15.55C7.57249 15.7974 7.7516 16 8 16C8.2484 16 8.42751 15.7974 8.45 15.55C8.6 13.9 11.1578 12.328 12.2435 11.2417C13.3289 10.156 14 8.6563 14 7C14 3.6862 11.3138 1 8 1Z" fill="#FF4433"/>
              <path d="M8.00002 9.10015C9.15982 9.10015 10.1 8.15994 10.1 7.00015C10.1 5.84035 9.15982 4.90015 8.00002 4.90015C6.84023 4.90015 5.90002 5.84035 5.90002 7.00015C5.90002 8.15994 6.84023 9.10015 8.00002 9.10015Z" fill="white"/>
            </svg>
            {t('open_in_yandex_map')}
          </a>
        </div>
      </Modal>
    </div>
  )
};

export default Addition;