import React from 'react';

import Slider from "./components/Slider";

import classes from './Intro.module.scss';

const Intro = ({ item }) => (
  <section className={classes.section}>
    <Slider item={item} />
  </section>
);

export default Intro;
