import React from 'react';

import { useList } from 'modules/album/hooks';

import Spinner from "components/Spinner";
import Slider from "./components/Slider";

import classes from './Album.module.scss';

const Album = () => {
  const { items, isFetched } = useList();

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <Slider items={items} />
    </section>
  )
};

export default Album;
