import React from 'react';

import classes from "../ObjectSingle.module.scss";

const Main = ({ src, name, title, info }) => (
  <div>
    <h2 className={classes.name}>{name}</h2>

    <p className={classes.title}>{title}</p>

    <img className={classes.photo} src={src} alt={name} width='100%' height={420} />

    <p className={classes.info}>{info}</p>
  </div>
);

export default Main;