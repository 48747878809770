import React from 'react';
import { useTranslation } from "react-i18next";

import Slider from "components/Slider";

import classes from '../ObjectSingle.module.scss';

const Pictures = ({ photos }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.slider}>
      <h3>{t('various_pictures')}</h3>

      <Slider
        items={photos}
        slide={photo => (
          <img src={photo} alt="" width='100%' height={432} />
        )}
      />
    </div>
  )
};

export default Pictures;