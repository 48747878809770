import React from 'react';

import Hero from './sections/Hero';
import Games from './sections/Games';
import Foods from './sections/Foods';
import Shrines from './sections/Shrines';
import Regions from "./sections/Regions";

const Home = () => (
  <>
    <Hero />

    <Shrines />

    <Games />

    <Foods />

    <Regions />
  </>
);

export default Home;