import React from 'react';
import { useTranslation } from "react-i18next";

import Main from "./components/Main";
import Addition from "./components/Addition";

import classes from './Hero.module.scss';

const Hero = ({ item }) => {
  const { i18n } = useTranslation();

   return (
      <section className={classes.section}>
         <div className="container">
            <div className={classes.content}>
               <Addition
                 address={item.address}
                 location={item.location}
               />

               <Main
                 src={item.photo[0]}
                 name={item.name[i18n.language]}
                 title={item.title[i18n.language]}
                 info={item.info[i18n.language]}
                 addTitle={item.addTitle[i18n.language]}
                 addInfo={item.addInfo[i18n.language]}
               />
            </div>
         </div>
      </section>
   )
}

export default Hero;