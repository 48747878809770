import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { IoCloseOutline } from "react-icons/io5";
import cx from "classnames";

import classes from '../Header.module.scss';

const Navigation = ({ show, setShow }) => {
  const { t } = useTranslation();

  return (
    <nav className={cx(classes.navigation, show && classes.open)}>
      <ul>
        <li>
          <NavLink className={({ isActive }) => cx(isActive && classes['active'])} to='/'>{t('nav.home')}</NavLink>
        </li>

        <li>
          <NavLink className={({ isActive }) => cx(isActive && classes['active'])} to='/regions'>{t('nav.regions')}</NavLink>
        </li>

        <li>
          <NavLink className={({ isActive }) => cx(isActive && classes['active'])} to='/essential'>{t('nav.essential')}</NavLink>
        </li>

        <li>
          <NavLink className={({ isActive }) => cx(isActive && classes['active'])} to='/photo-album'>{t('nav.photo_album')}</NavLink>
        </li>

        <li>
          <NavLink className={({ isActive }) => cx(isActive && classes['active'])} to='/about'>{t('nav.about')}</NavLink>
        </li>
      </ul>

      <div
        onClick={() => setShow(false)}
        className={classes.icon}
      >
        <IoCloseOutline size={32} />
      </div>
    </nav>
  )
}

export default Navigation;