import React from 'react';

import classes from './Spinner.module.scss';

const Spinner = ({ size = 50 }) => (
  <div className={classes.loader} style={{ '--size': `${size}px` }}>
    <svg viewBox="0 0 100 100">
      <defs>
        <filter id="shadow">
          <feDropShadow dx={0} dy={0} stdDeviation="1.5" floodColor="#0A2D56" />
        </filter>
      </defs>
      <circle className={classes.circle} cx={50} cy={50} r={45} />
    </svg>
  </div>
)

export default Spinner;