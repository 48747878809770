import React from 'react';
import { Map, Placemark } from "react-yandex-maps";

import classes from './RegionMap.module.scss';

const RegionMap = ({ location }) => (
  <section className={classes.section}>
    <div className="container">
      <div className={classes.content}>
        <Map
          className={classes.map}
          width='100%'
          height={400}
          defaultState={{
            center: [location.x, location.y],
            zoom: 9
          }}
        >
          <Placemark defaultGeometry={[location.x, location.y]} />
        </Map>
      </div>
    </div>
  </section>
);

export default RegionMap;