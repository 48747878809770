import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IoArrowForward } from 'react-icons/io5';

import * as Hooks from 'modules/essential/hooks';

import Spinner from "components/Spinner";

import classes from './SubEssential.module.scss';

const SubEssential = () => {
  const { t, i18n } = useTranslation();
  const { essentialId } = useParams();

  const { item, isFetched } = Hooks.useSingle({ essentialId });

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <div className='text-center'>
          <h2 className={classes.title}>{item.categoryName[i18n.language]}</h2>
        </div>

        <div className={classes.cards}>
          {item.essentials.map(item => (
            <div className={classes.card} key={item.id}>
              <div className={classes.image}>
                <img src={item.photo} alt="Sub essential img" width='100%' height={200} />
              </div>

              <div className={classes.info}>
                <h3>{item.name[i18n.language]}</h3>
                <p>{item.title[i18n.language]}</p>
                <a href={item.url} target='_blank' rel="noreferrer">{t('more')} <IoArrowForward /></a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SubEssential;