import React from 'react';
import { useTranslation } from "react-i18next";

import ParticipantCard from 'components/ParticipantCard';

import classes from '../About.module.scss';

const Participant = ({ items }) => {
   const { i18n } = useTranslation();

   return (
      <div className={classes.participant}>
         {items.map(item => (
           <ParticipantCard
             key={item.id}
             src={item.photo}
             name={item.name[i18n.language]}
             role={item.role[i18n.language]}
           />
         ))}
      </div>
   )
}

export default Participant;