import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './index.scss';

import config from "config";
import { http, i18n } from "services";

i18n.init({
  languages: config.language.list,
  currentLanguage: localStorage.getItem(config.language.key),
  initialLanguage: config.language.initial,
  backend: {
    loadPath: `${config.api.base_url}activeText/{{lng}}`
  },
  onChange: language => localStorage.setItem('language', language)
});

http.init({
  configFn: () => {
    return {
      baseURL: config.api.base_url
    };
  }
});