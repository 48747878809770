import React from 'react';
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/food/hooks';

import Slider from 'components/Slider';
import Card from 'components/Card';

import classes from './Foods.module.scss';

const Foods = () => {
  const { t, i18n } = useTranslation();

  const { items } = Hooks.useList();

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <div className={classes.driver} /> {t('national_foods')}
        </h2>

        <Slider
          items={items}
          btnVariant='white'
          slide={item => (
            <Card
              key={item.id}
              url={`/foods/${item.id}`}
              src={item.photo[0]}
              name={item.name[i18n.language]}
              description={item.title[i18n.language]}
            />
          )}
        />
      </div>
    </section>
  )
}

export default Foods;