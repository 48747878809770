const ENV = process.env;

const config = {
  api: {
    base_url: ENV.REACT_APP_SERVER_URL
  },
  language: {
    key: 'language',
    initial: 'oz',
    list: ['oz', 'uz', 'ru', 'en']
  },
  list: {
    limit: 10
  },
  pattern: {
    phone: /^[+]998(9[012345789]|6[125679]|7[01234569])[0-9]{7}$/
  }
};

export default config;


