import React  from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/slider/hooks';

import classes from '../Hero.module.scss';

const Slider = () => {
  const { i18n } = useTranslation();

  const { items } = Hooks.useList();

  return (
    <div className={classes.slider}>
      <Swiper
        loop
        speed={800}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        slidesPerView={1}
        modules={[Autoplay]}
      >
        {items.map(item => (
          <SwiperSlide key={item.id}>
            <div className={classes.slide}>
              <img className={classes.image} src={item.photo} alt='Banner' height={600} />

              <div className={classes.curtain} />

              <div className="container">
                <div className={classes.info}>
                  <h1 className={classes.title}>{item.title[i18n.language]}</h1>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;