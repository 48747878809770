import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/shrine/hooks';

import Slider from "components/Slider";

import classes from './Shrines.module.scss';

const Shrines = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { items } = Hooks.useList();

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <div className={classes.driver} /> {t('top_shrines')}
        </h2>

        <Slider
          items={items}
          slidesPerView={5}
          breakpoints={{
            0: {
              slidesPerView: 1
            },
            520: {
              slidesPerView: 2
            },
            720: {
              slidesPerView: 3
            },
            991: {
              slidesPerView: 4
            },
            1200: {
              slidesPerView: 5
            }
          }}
          slide={item => (
            <div onClick={() => navigate(`/shrines/${item.regionId}/${item.id}`)} className={classes.slide} key={item.id}>
              <img src={item.photo[0]} alt={item.name[i18n.language]} width={236} height={252} />
              <h3>{item.name[i18n.language]}</h3>
            </div>
          )}
        />
      </div>
    </section>
  )
}

export default Shrines;