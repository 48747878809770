import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import classes from './Breadcrumb.module.scss';

const Breadcrumb = ({ name }) => {
  const { t, i18n } = useTranslation();

  return (
    <section className={classes.section}>
      <div className="container">
        <div className={classes.breadcrumb}>
          <Link to='/'>{t('nav.home')} </Link>
          <Link to='/regions'> / {t('regions')} </Link>
          / {name[i18n.language]}
        </div>
      </div>
    </section>
  );
}

export default Breadcrumb;