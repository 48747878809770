import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { IoArrowForward } from 'react-icons/io5';

import classes from './Card.module.scss';

const Card = ({ src, name, description, url }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.card}>
      <div>
        <img src={src} alt={name} width='100%' height={200} />
      </div>

      <h3>{name}</h3>

      <p>{description}</p>

      <Link to={url}>
        <span>{t('more')}</span> <IoArrowForward/>
      </Link>
    </div>
  )
}

export default Card;