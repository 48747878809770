import React from 'react';
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/region/hooks';

import Spinner from "components/Spinner";
import RegionCard from 'components/RegionCard';

import classes from './Regions.module.scss';

const Regions = () => {
  const { t, i18n } = useTranslation();

  const { items, isFetched } = Hooks.useList();

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>{t('regions_of_uzbekistan')}</h2>

        <div className={classes.slider}>
          {items.map(item => (
            <RegionCard
              key={item.id}
              src={item.photo[0]}
              name={item.name[i18n.language]}
              description={item.shortInfo[i18n.language]}
              count={item.shrineCount}
              url={`/regions/${item.id}`}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Regions;