import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import * as Hooks from 'modules/shrine/hooks';

import Slider from "components/Slider";

import classes from './NearbyShrines.module.scss';

const NearbyShrines = () => {
  const { t, i18n } = useTranslation();
  const { regionId } = useParams();
  const navigate = useNavigate();

  const { items } = Hooks.useListByRegion({ id: regionId });

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <div className={classes.divider} /> {t('nearby_shrines')}
        </h2>

        <Slider
          items={items}
          slidesPerView={4}
          breakpoints={{
            0: {
              slidesPerView: 1
            },
            600: {
              slidesPerView: 2
            },
            991: {
              slidesPerView: 3
            },
            1200: {
              slidesPerView: 4
            }
          }}
          slide={item => (
            <div
              onClick={() => navigate(`/shrines/${item.regionId}/${item.id}`)}
              className={classes.slide}
              key={item.id}
            >
              <img src={item.photo[0]} alt="Nearby shrine img" height={350} />

              <div>
                <h3>{item.name[i18n.language]}</h3>
                <p>{item.title[i18n.language]}</p>
              </div>
            </div>
          )}
        />
      </div>
    </section>
  )
}

export default NearbyShrines;