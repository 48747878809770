import get from 'lodash/get';

export const Essential = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  photo: get(item, 'photo') || ''
});

export const SubEssential = (item) => ({
  categoryName: {
    oz: get(item, 'category_name.oz') || '',
    uz: get(item, 'category_name.uz') || '',
    ru: get(item, 'category_name.ru') || '',
    en: get(item, 'category_name.en') || ''
  },
  essentials: (get(item, 'data') || []).map(item => getSubEssential(item))
});

export const getSubEssential = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  photo: get(item, 'photo') || '',
  url: get(item, 'url') || '',
});