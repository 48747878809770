import get from 'lodash/get';

export const Object = (item) => ({
  id: get(item, 'id') || '',
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  info: {
    oz: get(item, 'info.oz') || '',
    uz: get(item, 'info.uz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || ''
  },
  address: {
    oz: get(item, 'address.oz') || '',
    uz: get(item, 'address.uz') || '',
    ru: get(item, 'address.ru') || '',
    en: get(item, 'address.en') || ''
  },
  type: {
    oz: get(item, 'type.oz') || '',
    uz: get(item, 'type.uz') || '',
    ru: get(item, 'type.ru') || '',
    en: get(item, 'type.en') || ''
  },
  location: {
    x: get(item, 'location.x') || '',
    y: get(item, 'location.y') || ''
  },
  regionId: get(item, 'region_id') || '',
  shrineId: get(item, 'shrine_id') || '',
  top: get(item, 'top') || null,
  phone: get(item, 'phone') || '+998',
  link: {
    facebook: get(item, 'link.facebook') || '',
    site: get(item, 'link.site') || '',
    instagram: get(item, 'link.instagram') || '',
    telegram: get(item, 'link.telegram') || '',
    youtube: get(item, 'link.youtube') || ''
  },
  workTime: get(item, 'work_time') || '',
  photo: get(item, 'photo') || []
});