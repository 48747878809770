import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { CgInsights } from 'react-icons/cg';
import { IoArrowForward } from 'react-icons/io5';

import classes from './RegionCard.module.scss';

const RegionCard = ({ src, name, description, count, url }) => {
  const { t } = useTranslation();

   return (
      <div className={classes.card}>
         <div className={classes.body}>
            <img src={src} alt={name} height={220} />

            <h3>{name}</h3>

            <p>{description}</p>

            <div className={classes.count}>
               <CgInsights fontSize={24} color='#285D53' />
               <span>{count} {t('shrines_count')}</span>
            </div>
         </div>

         <Link to={url}>
           {t('more')} <IoArrowForward />
         </Link>
      </div>
   )
}

export default RegionCard;