import React from 'react';

import Header from './Header';
import Footer from './Footer';

import classes from './Layout.module.scss';

const Layout = ({ children }) => (
  <div className={classes.wrapper}>
    <Header />

    <main className={classes.content}>
      {children}
    </main>

    <Footer />
  </div>
)
export default Layout;