import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import * as Hooks from 'modules/object/hooks';
import { TYPE } from "modules/object/constants";

import Slider from "components/Slider";

import classes from "./Restaurants.module.scss";

const Restaurants = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { items } = Hooks.useListByRegion({
    params: {
      id,
      type: TYPE.RESTAURANT
    }
  });

  if (!items.length) {
    return null;
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <div className={classes.driver} /> {t('restaurants')}
        </h2>

        <Slider
          items={items}
          btnVariant='white'
          slidesPerView={1}
          breakpoints={{}}
          slide={item => (
            <div
              onClick={() => navigate(`/object/${TYPE.RESTAURANT}/${item.id}`)}
              className={classes.slide}
              key={item.id}
            >
              <div className={classes.image}>
                <img src={item.photo[0]} alt={item.name[i18n.language]} width='100%' height={500} />
              </div>

              <div className={classes.info}>
                <h3>{item.name[i18n.language]}</h3>
                <p>{item.title[i18n.language]}</p>
              </div>
            </div>
          )}
        />
      </div>
    </section>
  )
}

export default Restaurants;