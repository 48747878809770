import React  from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import classes from '../Intro.module.scss';

const Slider = ({ item }) => (
  <div className={classes.slider}>
    <Swiper
      loop
      speed={800}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false
      }}
      pagination={{
        clickable: true
      }}
      slidesPerView={1}
      modules={[Autoplay]}
    >
      {item.photo.map(img => (
        <SwiperSlide key={img}>
          <div className={classes.slide}>
            <img src={img} alt='Region of Uzbekiston' width='100%' height={600} />

            <div className={classes.curtain} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

export default Slider;