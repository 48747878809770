import React from 'react';
import { useTranslation } from "react-i18next";

import * as AboutHooks from 'modules/about/hooks';
import * as LoaderHooks from 'modules/leader/hooks';
import * as ParticipantHooks from 'modules/participant/hooks';

import Spinner from "components/Spinner";

import Tabs from './components/Tabs';
import Manager from './components/Manager';
import Participant from './components/Participant';

import classes from './About.module.scss';

const About = () => {
  const { t } = useTranslation();

  const about = AboutHooks.useAbout();
  const loader = LoaderHooks.useList();
  const participant = ParticipantHooks.useList({
    params: {
      search: '',
      page: 1,
    }
  });


  if (!about.isFetched && !loader.isFetched && !participant.isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <div className={classes.driver} /> {t('about_project')}
        </h2>

        <Tabs item={about.item} />

        <Manager items={loader.items} />

        <Participant items={participant.items} />
      </div>
    </section>
  )
}

export default About;