import React from 'react';
import { useParams } from "react-router-dom";

import * as Hooks from "modules/shrine/hooks";

import Spinner from "components/Spinner";

import Hero from './sections/Hero';
import RegionMap from './sections/RegionMap';
import MediaContent from './sections/MediaContent';
import NearbyShrines from './sections/NearbyShrines';

const ShrineSingle = () => {
  const { shrineId } = useParams();

  const { item, isFetched } = Hooks.useSingle({ id: shrineId });

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <Hero item={item} />

      <MediaContent item={item} />

      <RegionMap location={item.location} />

      <NearbyShrines item={item} />
    </>
  )
}

export default ShrineSingle;