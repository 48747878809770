import React, { useState } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import cx from "classnames";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

import './Slider.scss';
import classes from './Slider.module.scss';

const Slider = ({ items, slide, slidesPerView = 3, btnVariant = 'green', ...props }) => {
  const [swipe, setSwipe] = useState();

  return (
    <div className={classes.wrapper}>
      {items.length > 3 && (
        <button onClick={() => swipe?.slidePrev()} className={cx(classes.prevBtn, classes[`prevBtn--${btnVariant}`])}>
          <IoArrowBack fontSize={24} />
        </button>
      )}

      <Swiper
        loop={items.length > 3}
        speed={800}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        spaceBetween={30}
        slidesPerView={slidesPerView}
        breakpoints={{
          0: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 2
          },
          991: {
            slidesPerView: 3
          }
        }}
        modules={[Autoplay]}
        onBeforeInit={(swiper) => setSwipe(swiper)}
        {...props}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            {slide(item)}
          </SwiperSlide>
        ))}
      </Swiper>

      {items.length > 3 && (
        <button onClick={() => swipe?.slideNext()} className={cx(classes.nextBtn, classes[`nextBtn--${btnVariant}`])}>
          <IoArrowForward fontSize={24} />
        </button>
      )}
    </div>
  );
};

export default Slider;