import React from "react";
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/game/hooks';

import Slider from 'components/Slider';
import Card from 'components/Card';

import classes from './Games.module.scss';

const Games = () => {
  const { t, i18n } = useTranslation();

  const { items } = Hooks.useList();

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <div className={classes.driver} /> {t('national_games')}
        </h2>

        <Slider
          items={items}
          slide={item => (
            <Card
              key={item.id}
              url={`/games/${item.id}`}
              src={item.photo[0]}
              name={item.name[i18n.language]}
              description={item.title[i18n.language]}
            />
          )}
        />
      </div>
    </section>
  )
}

export default Games;