import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { TbStack2 } from "react-icons/tb";
import { CgInsights } from "react-icons/cg";
import { HiOutlineLocationMarker } from "react-icons/hi";

import * as Hooks from 'modules/statistic/hooks';

import Slider from "components/Slider";

import classes from '../Hero.module.scss';

const Statistics = () => {
  const { t } = useTranslation();

  const { item } = Hooks.useStatistic();

  const items = useMemo(() => [
    {
      id: 1,
      name: item.regionCount > 1 ? t('regions') : t('region'),
      icon: <HiOutlineLocationMarker fontSize={20} />,
      count: item.regionCount
    },
    {
      id: 2,
      name: item.shrineCount > 1 ? t('shrines') : t('shrine'),
      icon: <CgInsights fontSize={20} />,
      count: item.shrineCount
    },
    {
      id: 3,
      name: item.objectCount > 1 ? t('additional_objects') : t('additional_object'),
      icon: <TbStack2 fontSize={20} />,
      count: item.objectCount
    }
  ], [t, item]);

  return (
    <div className={classes.statistics}>
      <Slider
        items={items}
        breakpoints={{
          0: {
            slidesPerView: 1
          },
          500: {
            slidesPerView: 2
          },
          720: {
            slidesPerView: 3
          }
        }}
        slide={item => (
          <div className={classes.item} key={item.id}>
            <span className={classes.icon}>{item.icon}</span>
            <strong className={classes.text}>{item.count} {item.name}</strong>
          </div>
        )}
      />
    </div>
  )
}

export default Statistics;