export const ENTITY = 'OBJECT';
export const ENTITY_BY_REGION = 'OBJECT_BY_REGION';
export const ENTITY_BY_SHRINE = 'OBJECT_BY_SHRINE';

export const TYPE = {
  PARK: 'park',
  BANK: 'bank',
  TRAIN: 'train',
  CLINIC: 'clinic',
  HOTEL: 'hotel',
  MARKET: 'market',
  MOSQUE: 'mosque',
  MUSEUM: 'museum',
  THEATER: 'theater',
  AIRPORT: 'airport',
  RESTAURANT: 'restaurant',
}