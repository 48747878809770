import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { BsBank } from 'react-icons/bs';
import { BiClinic } from 'react-icons/bi';
import { RiHotelLine } from 'react-icons/ri';
import { FaMosque } from "react-icons/fa";
import { GrRestaurant } from "react-icons/gr";
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { IoSparklesOutline, IoLocationOutline } from "react-icons/io5";
import { MdOutlineMuseum, MdOutlineTheaters, MdOutlineLocalAirport, MdOutlineTrain } from 'react-icons/md';

import * as Hooks from "modules/object/hooks";
import { TYPE } from 'modules/object/constants';

import Modal from 'components/Modal';

import classes from "../Hero.module.scss";

const Addition = ({ address, location }) => {
  const { t, i18n } = useTranslation();
  const { regionId, shrineId } = useParams();
  const [show, setShow] = useState(false);

  const { item: park } = Hooks.useListByShrine({
    params: {
      type: TYPE.PARK,
      regionId,
      shrineId
    }
  });

  const { item: restaurant } = Hooks.useListByShrine({
    params: {
      type: TYPE.RESTAURANT,
      regionId,
      shrineId
    }
  });

  const { item: hotel } = Hooks.useListByShrine({
    params: {
      type: TYPE.HOTEL,
      regionId,
      shrineId
    }
  });

  const { item: bank } = Hooks.useListByShrine({
    params: {
      type: TYPE.BANK,
      regionId,
      shrineId
    }
  });

  const { item: clinic } = Hooks.useListByShrine({
    params: {
      type: TYPE.CLINIC,
      regionId,
      shrineId
    }
  });

  const { item: market } = Hooks.useListByShrine({
    params: {
      type: TYPE.MARKET,
      regionId,
      shrineId
    }
  });

  const { item: mosque } = Hooks.useListByShrine({
    params: {
      type: TYPE.MOSQUE,
      regionId,
      shrineId
    }
  });

  const { item: museum } = Hooks.useListByShrine({
    params: {
      type: TYPE.MUSEUM,
      regionId,
      shrineId
    }
  });

  const { item: theater } = Hooks.useListByShrine({
    params: {
      type: TYPE.THEATER,
      regionId,
      shrineId
    }
  });

  const { item: airport } = Hooks.useListByShrine({
    params: {
      type: TYPE.AIRPORT,
      regionId,
      shrineId
    }
  });

  const { item: train } = Hooks.useListByShrine({
    params: {
      type: TYPE.TRAIN,
      regionId,
      shrineId
    }
  });

  return (
    <div className={classes.addition}>
      <h3>{t('amenities')}</h3>

      <ul>
        {!!location.x && !!location.y  && (
          <li>
            <div className={classes.icon}>
              <IoLocationOutline fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('address')}: </strong>
              <div onClick={() => setShow(true)} className={classes.link}>
                {address[i18n.language]}
              </div>
            </div>
          </li>
        )}

        {!!park.id && (
          <li>
            <div className={classes.icon}>
              <IoSparklesOutline fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('park')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.PARK}/${park.id}`}>
                {park.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!restaurant.id && (
          <li>
            <div className={classes.icon}>
              <GrRestaurant fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('restaurant')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.RESTAURANT}/${restaurant.id}`}>
                {restaurant.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!hotel.id && (
          <li>
            <div className={classes.icon}>
              <RiHotelLine fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('hotel')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.HOTEL}/${hotel.id}`}>
                {hotel.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!bank.id && (
          <li>
            <div className={classes.icon}>
              <BsBank fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('bank')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.BANK}/${bank.id}`}>
                {bank.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!clinic.id && (
          <li>
            <div className={classes.icon}>
              <BiClinic fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('clinic')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.CLINIC}/${clinic.id}`}>
                {clinic.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!market.id && (
          <li>
            <div className={classes.icon}>
              <AiOutlineShoppingCart fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('market')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.MARKET}/${market.id}`}>
                {market.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!mosque.id && (
          <li>
            <div className={classes.icon}>
              <FaMosque fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('mosque')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.MOSQUE}/${mosque.id}`}>
                {mosque.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!museum.id && (
          <li>
            <div className={classes.icon}>
              <MdOutlineMuseum fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('museum')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.MUSEUM}/${museum.id}`}>
                {museum.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!theater.id && (
          <li>
            <div className={classes.icon}>
              <MdOutlineTheaters fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('theater')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.THEATER}/${theater.id}`}>
                {theater.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!airport.id && (
          <li>
            <div className={classes.icon}>
              <MdOutlineLocalAirport fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('airport')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.AIRPORT}/${airport.id}`}>
                {airport.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}

        {!!train.id && (
          <li>
            <div className={classes.icon}>
              <MdOutlineTrain fontSize={20}/>
            </div>

            <div className={classes.text}>
              <strong>{t('train')}: </strong>
              <Link className={classes.link} to={`/object/${TYPE.TRAIN}/${train.id}`}>
                {train.name[i18n.language]}
              </Link>
            </div>
          </li>
        )}
      </ul>

      <Modal visible={show} onCancel={() => setShow(false)}>
        <div className={classes.maps}>
          <a className={classes.link} href={`https://www.google.com/maps/place/${location.x},${location.y}`} target='_blank' rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.3 132.3">
              <path fill="#1a73e8" d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"/>
              <path fill="#ea4335" d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"/>
              <path fill="#4285f4" d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"/>
              <path fill="#fbbc04" d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"/>
              <path fill="#34a853" d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"/>
            </svg>
            {t('open_in_google_map')}
          </a> <br/>
          <a className={classes.link} href={`https://yandex.ru/maps/?ll=${location.x},${location.y}&z=12`} target='_blank' rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 1C4.6862 1 2 3.6862 2 7C2 8.6563 2.6711 10.156 3.7565 11.2417C4.8422 12.328 7.4 13.9 7.55 15.55C7.57249 15.7974 7.7516 16 8 16C8.2484 16 8.42751 15.7974 8.45 15.55C8.6 13.9 11.1578 12.328 12.2435 11.2417C13.3289 10.156 14 8.6563 14 7C14 3.6862 11.3138 1 8 1Z" fill="#FF4433"/>
              <path d="M8.00002 9.10015C9.15982 9.10015 10.1 8.15994 10.1 7.00015C10.1 5.84035 9.15982 4.90015 8.00002 4.90015C6.84023 4.90015 5.90002 5.84035 5.90002 7.00015C5.90002 8.15994 6.84023 9.10015 8.00002 9.10015Z" fill="white"/>
            </svg>
            {t('open_in_yandex_map')}
          </a>
        </div>
      </Modal>
    </div>
  )
};

export default Addition;