import React from 'react';

import Slider from "./components/Slider";
import Statistics from "./components/Statistics";

import classes from './Hero.module.scss';

const Hero = () => (
  <section className={classes.section}>
    <Slider />

    <Statistics />
  </section>
);

export default Hero;
