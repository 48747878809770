import React from 'react';
import cx from 'classnames';
import { IoCloseOutline } from "react-icons/io5";

import classes from './Modal.module.scss';

const Modal = ({ visible, maxWidth = 650, onCancel, children }) => (
   <div
      onClick={onCancel && onCancel}
      className={cx(classes.wrapper, visible && classes['wrapper--open'])}
   >
      <div
         onClick={e => e.stopPropagation()}
         className={classes.content}
         style={{ '--max-width': `${maxWidth}px` }}
      >
         <IoCloseOutline
           onClick={onCancel}
           className={classes.closeIcon}
         />

         {children}
      </div>
   </div>
)

export default Modal;