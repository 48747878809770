import get from "lodash/get";

export const Shrine = (item) => ({
  id: get(item, 'id') || '',
  regionId: get(item, 'region_id') || '',
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  info: {
    oz: get(item, 'info.oz') || '',
    uz: get(item, 'info.uz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || ''
  },
  addTitle: {
    oz: get(item, 'add_title.oz') || '',
    uz: get(item, 'add_title.uz') || '',
    ru: get(item, 'add_title.ru') || '',
    en: get(item, 'add_title.en') || ''
  },
  addInfo: {
    oz: get(item, 'add_info.oz') || '',
    uz: get(item, 'add_info.uz') || '',
    ru: get(item, 'add_info.ru') || '',
    en: get(item, 'add_info.en') || ''
  },
  video: get(item, 'video') || '',
  address: {
    oz: get(item, 'address.oz') || '',
    uz: get(item, 'address.uz') || '',
    ru: get(item, 'address.ru') || '',
    en: get(item, 'address.en') || ''
  },
  audio: {
    oz: get(item, 'audio.oz') || '',
    uz: get(item, 'audio.uz') || '',
    ru: get(item, 'audio.ru') || '',
    en: get(item, 'audio.en') || ''
  },
  location: {
    x: get(item, 'location.x') || '',
    y: get(item, 'location.y') || '',
  },
  photo: get(item, 'photo') || []
});