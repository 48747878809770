import get from 'lodash/get';

export const Slider = (item) => ({
  id: get(item, 'id'),
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  photo: get(item, 'photo') || ''
});