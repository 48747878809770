import React from 'react';
import { useParams } from "react-router-dom";

import * as Hooks from 'modules/region/hooks';

import Spinner from "components/Spinner";

import Intro from "./sections/Intro";
import Parks from "./sections/Parks";
import Hotels from "./sections/Hotels";
import Shrines from "./sections/Shrines";
import Mosques from "./sections/Mosques";
import Markets from "./sections/Markets";
import Theaters from "./sections/Theaters";
import Breadcrumb from "./sections/Breadcrumb";
import Restaurants from "./sections/Restaurants";

const RegionSingle = () => {
  const { id } = useParams();

  const { item, isFetched } = Hooks.useSingle({ id });

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <Breadcrumb
        name={item.name}
      />

      <Intro
        item={item}
      />

      <Shrines
        name={item.name}
        shortInfo={item.shortInfo}
      />

      <Hotels />

      <Parks />

      <Theaters />

      <Restaurants />

      <Mosques />

      <Markets />
    </>
  );
}

export default RegionSingle;