import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from 'layout';

import Home from 'pages/Home';
import About from 'pages/About';
import Album from 'pages/Album';
import Search from "pages/Search";
import Regions from 'pages/Regions';
import Essential from 'pages/Essential';
import SubEssential from 'pages/SubEssential';

import GameSingle from "pages/GameSingle";
import FoodSingle from "pages/FoodSingle";
import RegionSingle from "pages/RegionSingle";
import ShrineSingle from "pages/ShrineSingle";
import ObjectSingle from "pages/ObjectSingle";

const App = () => (
  <Layout>
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/about" element={<About />} />

      <Route path="/regions" element={<Regions />} />

      <Route path="/regions/:id" element={<RegionSingle />} />

      <Route path="/object/:type/:id" element={<ObjectSingle />} />

      <Route path="/shrines/:regionId/:shrineId" element={<ShrineSingle />} />

      <Route path="/foods/:id" element={<FoodSingle />} />

      <Route path="/games/:id" element={<GameSingle />} />

      <Route path="/essential" element={<Essential />} />

      <Route path="/essential/:essentialId/subs" element={<SubEssential />} />

      <Route path="/photo-album" element={<Album />} />

      <Route path="/search" element={<Search />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Layout>
);

export default App;
