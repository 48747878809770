import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import classes from './EssentialCard.module.scss';

const EssentialCard = ({ src, url, name, description }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.card}>
      <div>
        <img src={src} alt={name} height={128} />
      </div>

      <h3>{name}</h3>

      <p>{description}</p>

      <Link to={url}>{t('more')}</Link>
    </div>
  )
};

export default EssentialCard;