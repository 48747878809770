import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as Hooks from "modules/object/hooks";
import { TYPE } from "modules/object/constants";

import Card from "components/Card";
import Slider from "components/Slider";

import classes from './Parks.module.scss';

const Parks = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { items } = Hooks.useListByRegion({
    params: {
      id,
      type: TYPE.PARK
    }
  });

  if (!items.length) {
    return null;
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <span className={classes.driver} /> {t('parks')}
        </h2>

        {items.length < 3 ? (
          <Slider
            items={items}
            btnVariant='white'
            slidesPerView={1}
            breakpoints={{}}
            slide={item => (
              <div
                onClick={() => navigate(`/object/${TYPE.PARK}/${item.id}`)}
                className={classes.slide}
                key={item.id}
              >
                <div className={classes.image}>
                  <img src={item.photo[0]} alt={item.name[i18n.language]} width='100%' height={500} />
                </div>

                <div className={classes.info}>
                  <h3>{item.name[i18n.language]}</h3>
                  <p>{item.title[i18n.language]}</p>
                </div>
              </div>
            )}
          />
        ) : (
          <Slider
            items={items}
            slide={item => (
              <Card
                key={item.id}
                src={item.photo[0]}
                name={item.name[i18n.language]}
                description={item.title[i18n.language]}
                url={`/object/${TYPE.PARK}/${item.id}`}
              />
            )}
          />
        )}
      </div>
    </section>
  );
}

export default Parks;