import get from 'lodash/get';

export const Game = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  photo: get(item, 'photo') || []
});

export const GameSingle = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  info: {
    oz: get(item, 'info.oz') || '',
    uz: get(item, 'info.uz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || ''
  },
  photo: get(item, 'photo') || []
});