import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CgFacebook } from 'react-icons/cg';
import { TbBrandTelegram } from 'react-icons/tb';
import { AiFillYoutube, AiFillAndroid } from "react-icons/ai";
import axios from 'axios';

import { http } from 'services';

import Logo from "assets/images/logo.png";

import classes from './Footer.module.scss';

const Footer = () => {
   const { t } = useTranslation();
   const [visit, setVisit] = useState({ local: 0, global: 0 });

   useEffect(() => {
      axios.get('https://ipapi.co/country/')
        .then(res => {
           let type = 'local';
           if (res.data !== 'UZ') {
              type = 'global';
           }
           http.request.get(`guests/${type}`)
             .then(res => {
                setVisit(res.data.data);
             })
             .catch(err => {
                console.log(err);
             });
        })
        .catch(err => {
           console.log(err);
        });
   }, []);

   return (
     <footer className={classes.footer}>
        <div className={classes.content}>
           <div className={classes.top}>
              <div className="container">
                 <div className={classes.topContent}>
                    <div>
                       <Link to='/'>
                          <img src={Logo} alt="Logo" width={75} height={75}/>
                       </Link>

                       <ul>
                          <li>
                             <NavLink to='/'>{t('nav.home')}</NavLink>
                          </li>
                          <li>
                             <NavLink to='/regions'>{t('nav.regions')}</NavLink>
                          </li>
                          <li>
                             <NavLink to='/essential'>{t('nav.essential')}</NavLink>
                          </li>
                          <li>
                             <NavLink to='/photo-album'>{t('nav.photo_album')}</NavLink>
                          </li>
                          <li>
                             <NavLink to='/about'>{t('nav.about')}</NavLink>
                          </li>
                       </ul>
                    </div>

                    <div className={classes.visit}>
                       <div className={classes.visitItem}>
                          <div className={classes.visitName}>
                             {t('count_of_local_visits')}
                          </div>
                          <div className={classes.visitCount}>
                             {visit.local}
                          </div>
                       </div>

                       <div className={classes.visitItem}>
                          <div className={classes.visitName}>
                             {t('count_of_global_visits')}
                          </div>
                          <div className={classes.visitCount}>
                             {visit.global}
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>

           <div className={classes.divider} />

           <div className={classes.bottom}>
              <div className="container">
                 <div className={classes.bottomContent}>
                    <ul className={classes.bottomText}>
                       <li>Illustration graphic art design format</li>
                       <li>Public domain license</li>
                    </ul>

                    <ul className={classes.bottomIcons}>
                       <li>
                          <a href="#link">
                             <CgFacebook fontSize={24} />
                          </a>
                       </li>

                       <li>
                          <a href="#link">
                             <AiFillYoutube fontSize={24} />
                          </a>
                       </li>

                       <li>
                          <a href="#link">
                             <TbBrandTelegram fontSize={24} />
                          </a>
                       </li>

                       <li>
                          <a href="https://api-frontend.ziyarahtourism.uz/public/media/e-turizm.apk" target='_blank' download rel="noreferrer">
                             <AiFillAndroid fontSize={24} />
                          </a>
                       </li>
                    </ul>

                    <ul className={classes.bottomText}>
                       <li>Illustration graphic art design format</li>
                       <li>Public domain license</li>
                    </ul>
                 </div>
              </div>
           </div>
        </div>
     </footer>
   )
};

export default Footer;