import { http } from 'services';

export const ListByRegion = ({ params }) => http.request.get(`activeObjects/${params.type}/${params.id}`);

export const ListByShrine = ({ params }) => http.request.get(`activeObjects/${params.type}`, {
  params: {
    reg_id: params.regionId,
    shrine_id: params.shrineId
  }
});

export const Single = ({ params }) => http.request.get(`activeObjects/${params.type}/single/${params.id}`);