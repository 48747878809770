import React from "react";
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/region/hooks';

import Map from "./components/Map";

import classes from './Regions.module.scss';

const Regions = () => {
  const { t } = useTranslation();

  const { items } = Hooks.useList();

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>
          <div className={classes.driver} /> {t('regions')}
        </h2>

        <Map items={items} />
      </div>
    </section>
  )
}

export default Regions;