import React from 'react';

import classes from './ParticipantCard.module.scss';

const ParticipantCard = ({ src, name, role }) => (
  <div className={classes.card}>
    <img src={src} alt={name} height={275} />

    <h3>{name}</h3>

    <i>{role}</i>
  </div>
);

export default ParticipantCard;