import React from 'react';
import { useTranslation } from "react-i18next";
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineEmail } from 'react-icons/md';

import classes from '../About.module.scss';

const Manager = ({ items }) => {
   const { i18n } = useTranslation();

   if (!items.length) {
      return null;
   }

   return (
      <div className={classes.manager}>
         <div>
            <img src={items[0].photo} alt={items[0].name[i18n.language]} height={400} />

            <div>
               <h3>{items[0].name[i18n.language]}</h3>

               <i>{items[0].role[i18n.language]}</i>

               <p>{items[0].info[i18n.language]}</p>

               <ul>
                  <li>
                     <div>
                        <FiPhoneCall fontSize={20} />
                     </div>

                     <span>{items[0].phone}</span>
                  </li>

                  <li>
                     <div>
                        <MdOutlineEmail fontSize={20} />
                     </div>

                     <span>{items[0].email}</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   )
}

export default Manager;