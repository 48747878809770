import React, {useMemo, useState} from 'react';
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { IoGlobeOutline } from 'react-icons/io5';

import classes from '../Header.module.scss';

const Language = () => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);

  const languages = useMemo(() => [
    { key: 'oz', value: t('uzbek_lang') },
    { key: 'uz', value: t('krill_lang') },
    { key: 'ru', value: t('rus_lang') },
    { key: 'en', value: t('english_lang') },
  ], []);

  const clickLanguage = (language) => {
    i18n.changeLanguage(language.key);
    setVisible(false);
  }

  return (
    <div className={classes.language}>
      <div className={classes.languageBox} onClick={() => setVisible(prev => !prev)}>
        <div className={classes.languageIcon}>
          <IoGlobeOutline fontSize={20}/>
        </div>
        {languages.find(language => language.key === i18n.language)?.value}
      </div>

      <ul className={cx(visible && classes['visible'])}>
        {languages.map(language => (
          <li onClick={() => clickLanguage(language)} key={language.key}>
            {language.value}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Language;