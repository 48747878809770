import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import cx from "classnames";

import classes from '../About.module.scss';

const Tabs = ({ item }) => {
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState('about');

  return (
    <div className={classes.tabs}>
      <ul>
        <li
          onClick={() => setContent('about')}
          className={cx(content === 'about' && classes['active'])}
        >
          {t('about_project')}
        </li>

        <li
          onClick={() => setContent('target')}
          className={cx(content === 'target' && classes['active'])}
        >
          {t('about_purpose')}
        </li>
      </ul>

      <p>{item[content][i18n.language]}</p>
    </div>
  )
}

export default Tabs;