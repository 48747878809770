import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useStatistic = () => {
  const initialData = { item: Mappers.Statistic() };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY],
    async () => {
      const { data } = await Api.Statistic();

      return {
        item: Mappers.Statistic(data)
      };
    },
    { initialData }
  );

  return { ...args, ...data };
};

export default useStatistic;
