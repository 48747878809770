import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { IoSearch } from 'react-icons/io5';

import classes from '../Header.module.scss';

const Search = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classes.search} onClick={() => navigate('/search')}>
      <IoSearch /> <span>{t('search')}</span>
    </div>
  )
}

export default Search;