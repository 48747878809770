import React from 'react';
import { useTranslation } from "react-i18next";

import * as Hooks from 'modules/essential/hooks';

import Spinner from "components/Spinner";
import EssentialCard from 'components/EssentialCard';

import classes from './Essential.module.scss';

const Essential = () => {
  const { t, i18n } = useTranslation();

  const { items, isFetched } = Hooks.useList();

  if (!isFetched) {
    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          height: 500
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <div className='text-center'>
          <h2 className={classes.title}>{t('essential')}</h2>
        </div>

        <div className={classes.list}>
          {items.map(item => (
            <EssentialCard
              key={item.id}
              src={item.photo}
              url={`/essential/${item.id}/subs`}
              name={item.name[i18n.language]}
              description={item.title[i18n.language]}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Essential;